import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/deploying-with-github-action",
  "date": "15 April 2021",
  "title": "Deploying with the Skpr Github Action",
  "summary": "How to deploy to Skpr using the Github Action",
  "author": "Kim Pepper",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "deploying"
  }, {
    "name": "github action"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Many development teams have embraced the convenience of using Github Actions for their CI/CD workflows. You can now save
time and effort by using the Skpr Deploy Github Action in your deploy workflow.`}</p>
    <h3>{`1. Set up credentials`}</h3>
    <p>{`In your Github project settings, add `}<inlineCode parentName="p">{`SKPR_USERNAME`}</inlineCode>{` and `}<inlineCode parentName="p">{`SKPR_PASSWORD`}</inlineCode>{` you were provided as Action Secrets.`}</p>
    <h3>{`2. Create a Github Action workflow`}</h3>
    <p>{`In your project add a `}<inlineCode parentName="p">{`.github/workflows/deploy-dev.yml`}</inlineCode>{` file with the following contents:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`name: deploy-dev
on:
  push:
    branches: [master]
env:
  SKPR_USERNAME: \${{ secrets.SKPR_USERNAME }}
  SKPR_PASSWORD: \${{ secrets.SKPR_PASSWORD }}

jobs:
  deploy:
    runs-on: ubuntu-latest
    steps:
      - name: Checkout Code
        uses: actions/checkout@v2
        with:
          fetch-depth: 0
      - name: Skpr Package and Deploy
        uses: skpr/package-deploy
        with:
          env: dev
          post-deploy: make deploy
`}</code></pre>
    <p>{`We will break this example down and explain each section.`}</p>
    <p>{`Deploying to each environment will require a separate workflow. In this example, we want to deploy to the `}<inlineCode parentName="p">{`dev`}</inlineCode>{`
environment whenever code is pushed to the `}<inlineCode parentName="p">{`master`}</inlineCode>{` branch.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`on:
  push:
    branches: [master]
`}</code></pre>
    <p>{`We supply our Skpr credentials as environment variables taken from the Secrets we added in step 1.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`env:
  SKPR_USERNAME: \${{ secrets.SKPR_USERNAME }}
  SKPR_PASSWORD: \${{ secrets.SKPR_PASSWORD }}
`}</code></pre>
    <p>{`We next define our jobs running on a standard Ubuntu machine image:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`jobs:
  deploy:
    runs-on: ubuntu-latest
`}</code></pre>
    <p>{`Our first step simply checks out our code. We need to provide `}<inlineCode parentName="p">{`fetch-depth: 0`}</inlineCode>{` as we generate the version based off
`}<inlineCode parentName="p">{`git describe --tags`}</inlineCode>{` and need the full git history for that.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`    steps:
      - name: Checkout Code
        uses: actions/checkout@v2
        with:
          fetch-depth: 0
`}</code></pre>
    <p>{`Now to the Skpr specific configuration. We simply declare our `}<inlineCode parentName="p">{`skpr/package-deploy`}</inlineCode>{` Action and provide it with two
configuration options:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`env`}</em>{`: the Skpr environment we want to deploy to.`}</li>
      <li parentName="ul"><em parentName="li">{`post-deploy`}</em>{`: the commands we want to run on this environment immediately after deploy.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`      - name: Skpr Package and Deploy
        uses: skpr/package-deploy
        with:
          env: dev
          post-deploy: make deploy
`}</code></pre>
    <h3>{`Deploying an existing packaged version`}</h3>
    <p>{`Some workflows will require an approval step (e.g. staging) and then deploy the exact same version to a production
environment.`}</p>
    <p>{`This requires listening for the `}<inlineCode parentName="p">{`workflow_dispatch`}</inlineCode>{` event and taking the `}<inlineCode parentName="p">{`env`}</inlineCode>{` and `}<inlineCode parentName="p">{`version`}</inlineCode>{` parameters as inputs.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`on:
  workflow_dispatch:
    inputs:
      env:
        description: The environment to deploy to
        required: true
        default: dev
      version:
        description: The version. Must be an existing packaged version.
        required: true
`}</code></pre>
    <p>{`We can skip the `}<inlineCode parentName="p">{`package`}</inlineCode>{` step in our step definition, and use the `}<inlineCode parentName="p">{`env`}</inlineCode>{` and `}<inlineCode parentName="p">{`version`}</inlineCode>{` parameters here.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`      - name: Skpr Deploy
        uses: skpr/package-deploy
        with:
          env: \${{ github.event.inputs.env }}
          version: \${{ github.event.inputs.version }}
          package: false
          post-deploy: make deploy
`}</code></pre>
    <p>{`Here's the full example of a `}<inlineCode parentName="p">{`prod`}</inlineCode>{` deployment workflow, where the application was packaged in a previous workflow.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`name: deploy-prod
on:
  workflow_dispatch:
    inputs:
      env:
        description: The environment to deploy to
        required: true
        default: dev
      version:
        description: The version. Must be an existing packaged version.
        required: true

env:
  SKPR_USERNAME: \${{ secrets.SKPR_USERNAME }}
  SKPR_PASSWORD: \${{ secrets.SKPR_PASSWORD }}

jobs:
  deploy:
    runs-on: ubuntu-latest
    steps:
      - name: Checkout Code
        uses: actions/checkout@v2
        with:
          fetch-depth: 0
      - name: Skpr Deploy
        uses: skpr/package-deploy
        with:
          env: \${{ github.event.inputs.env }}
          version: \${{ github.event.inputs.version }}
          package: false
          post-deploy: make deploy
`}</code></pre>
    <p>{`You can trigger a deploy to `}<inlineCode parentName="p">{`prod`}</inlineCode>{` directly from the Actions page in your Github project by specifying the environment
and version parameters.`}</p>
    <p>{`As you can see, deploying to Skpr using the Skpr Github Action is easy and intuitive, and will suit development teams
who are considering using Github Actions for their CI/CD workflows.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      